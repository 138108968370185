import React, { useState, useEffect } from 'react'
import Api from '../../../state/apis/api'

export default function Shipping() {
  const [orders, setOrders] = useState('')
  const [orderNumbers, setOrderNumbers] = useState([])

  const processOrders = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSuccess(false)
    setLoading(true)
    try {
      const _resp = await Api.post('wwwREST', '/v1/private/admin/shipping?1=1', {
        body: { orders: orderNumbers },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })
    } catch (err) {
      console.error(JSON.stringify(err, null, 2))
    } finally {
      setSuccess(true)
      setLoading(false)
    }
  }

  // const [isHovering1, setIsHovering1] = useState(false)
  const [isHovering2, setIsHovering2] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    orders === '' ? setVisibility(false) : setVisibility(true)

    setOrderNumbers(orders.split('\n'))
  }, [orders])

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <br />
      <h1>Chargebee Order Numbers</h1>
      <p>
        This is an alternative for if Shipstation is having tech issues. Submit Chargebee order numbers, one per line,
        and you'll receive an email with the packing slips, DPD labels, dog name sticker and transition slips.
      </p>
      <br />
      <form>
        <textarea
          data-cy="order-input"
          data-testid="textarea"
          style={{ minHeight: '300px', minWidth: '300px', borderRadius: '5px', border: '3px solid #c6dabb' }}
          value={orders}
          onChange={e => setOrders(e.target.value)}
        ></textarea>

        {visibility && (
          <button
            data-testid="processOrders"
            onMouseEnter={() => setIsHovering2(true)}
            onMouseLeave={() => setIsHovering2(false)}
            style={{
              backgroundColor: isHovering2 && !loading ? '#006685' : '#88898a',
              margin: '10px',
              width: '150px',
              padding: '5px',
              borderRadius: '5px',
              color: 'white'
            }}
            onClick={processOrders}
            disabled={loading}
          >
            Process Orders
          </button>
        )}
        {visibility && success && <div data-testid="emailSent">You will be emailed shortly!</div>}
      </form>
    </div>
  )
}
